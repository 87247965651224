<template>
    <div>
        <b-modal
            id="modal-request"
            :title="i18nT(`Time off request`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            :ok-disabled="!requestEmployee || !requestType || !requestRange.start || !requestRange.end || currentLeave.NotEmployee === 1"
            no-close-on-backdrop
            @ok="saveLeaveRequest"
            @hidden="resetForm"
        >
            <validation-observer ref="requestForm">
                <b-form>
                    <b-row>
                        <b-col md="12">
                            <p>{{ i18nT(`Request time off and select the type of absence.`) }}</p>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12" v-if="!isFreelancer && currentLeave.NotEmployee !== 1">
                            <b-form-group
                                :label="i18nT(`Employee`)"
                                label-for="message"
                                class="required"
                            >
                              <mini-employee-card
                                  :employeeId="requestEmployee" />
                                <validation-provider
                                    #default="{ errors }"
                                    name="To"
                                    rules="required"
                                >
                                    <v-select
                                        :disabled="isFreelancer"
                                        v-model="requestEmployee"
                                        :options="employeeFilterOptions"
                                        :clearable="false"
                                        :state="errors.length > 0 ? false : null"
                                        :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                        label="text"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group
                                :label="i18nT(`Absence type`)"
                                label-for="message"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="To"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="requestType"
                                        :options="requestTypeOptions"
                                        :disabled="currentLeave.NotEmployee === 1"
                                        placeholder=""
                                        :clearable="false"
                                        :state="errors.length > 0 ? false : null"
                                        :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                        label="text"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                        </b-col>
                        <transition name="fade" >
                            <b-col cols="6" v-if="requestEmployee && requestType && currentLeave.NotEmployee !== 1" class="text-center">
                                <b-form-group
                                    :label="i18nT(`Request year`)"
                                    label-for="year"
                                    class="required"
                                >
                                    <b-form-select
                                        v-model="requestYear"
                                        :options="availableYears"
                                        class="d-inline"
                                        @change="updateLeaveStats(requestYear)">

                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                        </transition>
                        <transition name="fade" >
                            <b-col cols="3" v-if="requestEmployee && requestType && currentLeave.NotEmployee !== 1">
                                <div v-if="requestYear == currentYear && !isFreelancer" class="pt-1">
                                    <font-awesome-icon
                                        icon="edit"
                                        class="mt-2 text-primary cursor-pointer"
                                        v-b-tooltip.hover="i18nT(`Edit time off allowance for current year`)"
                                        @click="setEditableLeave(requestYear)">
                                    </font-awesome-icon>
                                </div>

                            </b-col>
                        </transition>
                    </b-row>

                    <transition name="fade" >
                        <b-row  v-if="requestYear && DiscountTime && currentLeave.NotEmployee !== 1">
                            <b-col sm="12">
                                <div class="d-flex mb-2">
                                    <div class="bg-light text-center p-1 mr-1 rounded w-100">
                                        <p class="font-weight-bolder">{{ i18nT(`Total`) }}</p>
                                        <p class="font-weight-bolder leave-stats"
                                        > {{ totalDays }} </p>
                                        <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                    </div>

                                    <div class="bg-light text-center mr-1 p-1 rounded w-100">
                                        <p class="font-weight-bolder">{{ i18nT(`Taken`) }}</p>
                                        <p class="text-danger font-weight-bolder leave-stats">
                                            {{ takenDays }}
                                        </p>
                                        <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                    </div>

                                    <div class="bg-light text-center mr-1 p-1 rounded w-100">
                                        <p class="font-weight-bolder">{{ i18nT(`Available`) }}</p>
                                        <p class="text-warning font-weight-bolder leave-stats"
                                           style=""
                                        >{{ availableDays }} </p>
                                        <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                    </div>

                                    <div class="bg-light text-center mr-1 p-1 rounded w-100">
                                        <p class="font-weight-bolder">{{ i18nT(`Requested`) }}</p>
                                        <p class="text-primary font-weight-bolder leave-stats"
                                        >{{ requestedInterval }} </p>
                                        <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                    </div>


                                </div>
                            </b-col>

                        </b-row>
                    </transition>
                    <transition name="fade" >
                        <b-row v-if="requestYear">
                            <b-col md="12">
                                <b-form-group
                                    :label="i18nT(`Select dates`)"
                                    label-for="message"
                                    class="required"
                                >

                                    <date-picker
                                        :disabled="currentLeave.NotEmployee === 1"
                                        :key="requestYear+'-from'"
                                        v-model="requestRange"
                                        :max="maxRequestTo"
                                        :min="minRequestFrom"
                                        :disabledWeekly="disabledWeekly"
                                        :range="true"
                                        :placeholder="i18nT(`Select your dates`)"
                                    />

                                </b-form-group>
                            </b-col>
                            <b-col
                                v-if="false"
                                md="12">
                                <b-form-group
                                    :label="i18nT(`To date`)"
                                    label-for="message"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="To"
                                        rules="required"
                                    >
                                        <date-picker
                                            :key="requestYear+'-to'"
                                            v-model="requestTo"
                                            :min="requestFrom"
                                            :max="maxRequestTo"
                                            placeholder=""
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="12">
                                <b-form-group
                                    :label="i18nT(`Note`)"
                                    label-for="Note"
                                >
                                    <b-form-textarea
                                        :disabled="currentLeave.NotEmployee === 1"
                                        :placeholder="i18nT(`Note`)"
                                        v-model="requestDescription"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="12">
                                <p>{{ i18nT(`Attached scans and documents`) }}</p>
                                <div v-if="currentLeave && currentLeave.AttachedFile">
                                    <b-link :href="currentLeave.AttachedFile.DownloadUrl">
                                        <feather-icon icon="FileIcon" size="19" class="mb-0"/>
                                        {{ currentLeave.AttachedFile.Label }}
                                    </b-link>
                                </div>
                            </b-col>
                            <b-col cols="12" md="12">
                                <b-button
                                    variant="outline-primary"
                                    class="mr-1 mb-1 mt-2"
                                    @click="onFilePick"
                                    :disabled="currentLeave.NotEmployee === 1"
                                >
                                    <feather-icon icon="PlusCircleIcon"/>
                                    {{ i18nT(addFileLabel) }}
                                </b-button>
                                <b-form-file
                                    v-model="file"
                                    ref="filePicker"
                                    class="hidden"
                                />
                                <span v-if="file">
                                            {{ fileName }}
                                            <feather-icon
                                                icon="Trash2Icon"
                                                size="16"
                                                style="margin-right: 8px;"
                                                @click="onFileDelete"
                                                class="cursor-pointer"
                                            />
                                        </span>
                                <!-- <span v-if="!file">
                                            {{ originalFileName }}
                                        </span> -->

                            </b-col></b-row>
                    </transition>

                </b-form>
            </validation-observer>
        </b-modal>

        <modal-leaves-edit ref="modalLeavesEdit" :leaveDay="leaveToEdit" @onLeaveUpdated="onLeaveUpdated" />
    </div>

</template>

<script>
import {
    BButton,
    BCol,
    BForm,
    BFormFile,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BLink,
    BModal,
    BRow,
    VBTooltip,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DatePicker from "@core/components/DatePicker.vue";
import moment from "moment";
import ModalLeavesEdit from "@/views/hr/LeaveRequest/ModalLeavesEdit.vue";
import useAuth from '@/auth/useAuth'
import MiniEmployeeCard from "@/views/common/Employees/MiniEmployeeCard.vue";

//import {dictToSelectArray} from "@core/utils/utils";

export default {
    name: "ModalLeaveRequest",
    components: {
        ModalLeavesEdit,
        BFormSelect,
        vSelect,
        BFormTextarea,
        BLink,
        BFormFile,
        BRow,
        BForm,
        BButton,
        ValidationProvider,
        ValidationObserver,
        DatePicker,
        BModal,
        BFormGroup,
        BCol,
        MiniEmployeeCard

    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    props: {
        curUserId: {
            type: String,
            default: null
        },
        extEmployeeId: {
            type: Number,
            default: null
        },
        extAbsenceTypeId: {
            type: String,
            default: null
        },
        extLeave: {
            type: Object,
            default: null
        },
        refetchData: {
            type: Function,
            default: null
        }
    },
    data: () => ({
        requestYear: null,
        availableYears: [],
        allLeaves: [],
        availableDays: 0,
        takenDays: 0,
        totalDays: 0,
        requestEmployee: null,
        DiscountTime: 0,
        currentLeave: {},
        leaveToEdit: {},
        absenceObjects: [],
        isFreelancer: false,
        daysOfWeek: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
        disabledWeekly: [],

        requestRange: {start: null, end: null},
        requestFrom: '',
        requestTo: '',
        requestedInterval: 0,
        requestTypeOptionsForm: [],
        requestTypeOptions: [],
        requestDescription: '',
        employeeFilterOptions: [],
        employeeFilterOptions_form: [],
        requestType: null,
        file: null,
        fileName: null,
        originalFileName: null,

    }),
    created() {
        this.isFreelancer = useAuth.getUserType() === "Freelancer" ? true : false
        if(this.extEmployeeId) {
            this.requestEmployee = this.extEmployeeId
        }
        this.getAbsenceTypes();
        this.getEmployeeFilter();
    },
    computed: {
        currentAbsenceType() {
            let absenceObj = this.absenceObjects.find(option => option.Id === this.requestType);
            console.log("Absence object: ", absenceObj);
            return absenceObj;
        },
        currentYear() {
            return moment().format('YYYY')
        },
        addFileLabel() {
            if (this.currentLeave && this.currentLeave.AttachedFile) {
                return this.i18nT('Replace file')
            } else {
                return this.i18nT('Add file')
            }
        },
        maxRequestTo() {
            if (this.requestYear) {
                return moment(this.requestYear + '-12-31').format('YYYY-MM-DD')
            } else {
                return null
            }
        },
        minRequestFrom() {
            if (this.requestYear) {
                return moment(this.requestYear + '-01-01').format('YYYY-MM-DD')
            } else {
                return null
            }
        },
    },
    methods: {

        onLeaveUpdated() {
            this.getEmployeeLeaveDays(this.requestEmployee)
            //this.$bvModal.hide('modal-edit-leave')
        },
        setEditableLeave(year) {
            this.leaveToEdit = this.allLeaves.find((item) => item.Year == year)
            this.$bvModal.show('modal-edit-leave')
        },
        getEmployeeFilter() {
            this.$http.get(`leaves/employeesList`).then(({data}) => {
                this.employeeFilterOptions = data.data.map(item => ({
                    value: item.Id,
                    text: item.Position ? item.Label+ " ("+item.Position+")" : item.Label
                }))
            })
        },
        getAbsenceTypeTitle(typeId) {
            try {
                return this.requestTypeOptions.find(option => option.value === typeId).text
            } catch (ex) {
                console.log(ex)
                return ''
            }
        },
        getAbsenceTypes() {
            this.$http.get(`hr/absenceTypes`).then(({data}) => {
                this.absenceObjects = data.data;
                let types = data.data.map(item => ({
                    value: item.Id,
                    text: item.Label
                }))
                this.requestTypeOptionsForm = types;
                this.requestTypeOptions = [...this.requestTypeOptions, ...types]
            })
        },
        resetForm() {

            this.$emit('resetForm')

            // if(!this.isFreelancer) {
            //     this.requestEmployee = 0
            //     // this.extLeave = null
            // }
            // //this.availableDays = 0
            // //this.takenDays = 0
            // //this.totalDays = 0
            // this.requestYear = null
            // this.currentLeave = {}
            // this.requestDescription = ''
            // this.file = null
            // this.fileName = null
            // this.originalFileName = null
            // this.requestFrom = ''
            // this.requestTo = ''
            // this.requestType = ''
            // // this.requestRange = {start: null, end: null}
        },

        getEmployeeLeaveDays(employeeId) {
            if (employeeId && this.currentLeave.NotEmployee !== 1) {
                this.$http
                    .get(`leaves/leaveDays?employee_id=${employeeId}`)
                    .then(({data}) => {
                        if(data.data.leave_days.length > 0){
                            this.allLeaves = data.data.leave_days;
                            this.availableYears = data.data.leave_days.map((item) => {
                                return {
                                    value: item.Year,
                                    text: item.Year
                                }
                            })
                        }
                        if(this.availableYears.length > 0 && !this.currentLeave){
                            this.requestYear = this.availableYears[0].value
                        }

                        const work_week = data.data.work_week.split(',')

                        this.daysOfWeek.forEach((day, index) => {
                            const dayNumber = work_week.indexOf(day);
                            if (dayNumber === -1) {
                                this.disabledWeekly.push(index);
                            }
                        });
                        
                        this.updateLeaveStats(this.requestYear)
                    })
            }
        },
        updateLeaveStats(year) {
            if (year){
                let yearLeaves = this.allLeaves.filter((item) => item.Year == year)
                if(yearLeaves){
                    this.availableDays = yearLeaves[0].AvailableDays
                    this.takenDays = yearLeaves[0].TakenDays
                    this.totalDays = yearLeaves[0].TotalDays
                    // this.requestFrom = ''
                    // this.requestTo = ''
                }
            }
        },

        onFilePick: function() {
            this.$refs['filePicker'].$refs.input.click()
        },

        onFileDelete: function() {
            this.file = null
            this.fileName = ''
        },

        saveLeaveRequest(event) {
            event.preventDefault();
            this.$refs.requestForm.validate().then((success) => {
                if (success) {
                    //TODO
                    const formData = new FormData();
                    if(this.currentLeave) {
                        formData.append("id", this.currentLeave.Id);
                    }

                    formData.append("UserId", this.requestEmployee);
                    formData.append("AbsenceType", this.requestType);
                    formData.append("StartDate", this.requestFrom);
                    formData.append("EndDate", this.requestTo);
                    //formData.append("HalfDay", 0);
                    formData.append("Description", this.requestDescription);
                    formData.append('attached_file', this.file)

                    this.$http
                        .post("leaves", formData)
                        .then((res) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: this.successMessage(res),
                                    icon: "InfoIcon",
                                    variant: "success",
                                },
                            });
                            this.selectedItems = [];
                            this.$bvModal.hide("modal-request");
                            this.resetForm()
                            this.refetchData();
                            this.getEmployeeLeaveDays(this.curUserId);
                        })
                        .catch((ex) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: "AlertTriangleIcon",
                                    variant: "danger",
                                },
                            });
                        });
                }
            });
        },

    },
    watch: {
        leaveId: {
            handler (val) {
                if(val){
                    //debugger;
                    this.currentLeave = this.extLeave;
                    this.requestEmployee = this.extLeave.UserId;
                    this.requestType = this.extLeave.AbsenceType;
                    this.requestRange = {start: this.extLeave.StartDate, end: this.extLeave.EndDate};
                    this.requestYear = moment(this.extLeave.StartDate).format('YYYY');

                    this.requestDescription = this.extLeave.Description;
                    this.fileName = this.extLeave.AttachedFile ? this.extLeave.AttachedFile.Label : null;
                    this.originalFileName = this.extLeave.AttachedFile ? this.extLeave.AttachedFile.Label : null;
                }
            },
            deep: true
        },
        extLeave: {
            handler (val) {
                if(val){
                    if(val.StartDate) {
                        this.requestYear = moment(val.StartDate).format('YYYY');
                    } 
                    else {
                        this.requestYear = null
                    }
                    this.currentLeave = val;
                    if(!this.isFreelancer){
                        this.requestEmployee = val.UserId;
                    }
                    this.requestType = val.AbsenceType;
                    this.requestRange = {start: val.StartDate, end: val.EndDate};
                    this.requestDescription = val.Description;
                    this.file = val.file ? val.file : null
                    this.fileName = val.AttachedFile ? val.AttachedFile.Label : null;
                    // this.originalFileName = val.AttachedFile ? val.AttachedFile.Label : null;
                }
            },
            deep: true
        },
        extEmployeeId: function (val) {
            if(val){
                this.requestEmployee = val;
                this.getEmployeeLeaveDays(val)
            }
        },
        extAbsenceTypeId: function (val) {
            if(val){
                this.requestType = val;
            }
        },
        currentAbsenceType: function (val) {
            if(val){
                this.DiscountTime = val.DiscountTime;
            }
        },
        requestEmployee: function (val) {
            this.disabledWeekly = []
            if(!this.extLeave) {
                this.requestRange = {start: null, end: null}
            }
            if(val){
                this.getEmployeeLeaveDays(val)
            }
        },
        requestType: function (val) {
            console.log("requestType", val)
        },
        requestRange: function (range) {
            if(range && range.end && range.start){
                this.requestFrom = range.start;
                this.requestTo = range.end;

                if(this.currentLeave.NotEmployee !== 1) {
                    this.$http.post('leaves/workingDays?', {
                        StartDate: range.start,
                        EndDate: range.end,
                        EmployeeId: this.requestEmployee,
                    }).then(({data}) => {
                        this.requestedInterval = data.data.working_days;
                    });
                }

                this.requestedInterval = moment(range.end).diff(moment(range.start), 'days') + 1;

            }else {
                this.requestedInterval = 0;
            }
        },
        file: function (val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        }
    }
}
</script>

<style scoped lang="scss">
.leave-stats {
    font-size: 26px;
    line-height: 10px;
}
</style>
