<template>
    <div>
        <h1>{{i18nT(`Time off requests`)}}</h1>
        <b-row>
            <b-col cols="12" md="3">
                <b-form-group :label="i18nT(`From`)">
                    <date-picker
                        v-model="from"
                        :placeholder="i18nT(`From Date`)"
                        :max="to"
                        :start-weekday="1"
                        :locale="`en-UK`"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
                <b-form-group :label="i18nT(`To`)">
                    <date-picker
                        reset-button
                        v-model="to"
                        :placeholder="i18nT(`To Date`)"
                        :min="from"
                        :start-weekday="1"
                        :locale="`en-UK`"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
                <b-form-group :label="i18nT(`Leave Type`)">
                    <b-form-select v-model="type" :options="requestTypeOptions" />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
                <b-form-group :label="i18nT(`Status`)">
                    <b-form-select v-model="status" :options="approvalStatusOptions" />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
                <b-form-group :label="i18nT(`Created By`)">
                    <b-form-select
                        v-model="createdBy"
                        :options="createdByOptions"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <div class="mb-1">
            <span class="text-muted">{{i18nT(`Showing`)}} {{ dataMeta.from }} {{i18nT(`to`)}} {{ dataMeta.to }} {{i18nT(`of`)}} {{ dataMeta.of }} {{i18nT(`entries`)}}</span>
        </div>
        <b-card no-body>
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        cols="12"
                    >
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block"
                        />
                        <b-button
                            variant="primary"
                            @click="preloadAndShow"
                            class="float-right"
                        >
                            {{i18nT(`Request`)}}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
<!--             <b-row>-->
<!--            <b-col cols="12" class="text-align-right">-->
<!--              <div class="sk-column-control">-->
<!--                <b-dropdown-->
<!--                    no-caret-->
<!--                    variant="button"-->
<!--                    toggle-class="p-0"-->
<!--                    :right="true"-->
<!--                    class="pr-2 pb-2 mb-1 d-flex sk-column-filter"-->
<!--                    v-b-tooltip = "i18nT(`Customize columns`)"-->
<!--                >-->
<!--                  <template #button-content>-->
<!--                    <feather-icon-->
<!--                        icon="SettingsIcon"-->
<!--                        size="18"-->
<!--                        class="text-right"-->
<!--                    />-->
<!--                  </template>-->

<!--                  <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">-->
<!--                    <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>-->
<!--                  </div>-->
<!--                </b-dropdown>-->
<!--              </div>-->
<!--            </b-col>-->

<!--          </b-row>-->
            <div>
                <b-table
                    ref="refDocumentListTable"
                    :items="fetchRequests"
                    responsive
                    :fields="computedColumns"
                    primary-key="id"
                    :sort-by.sync="sortBy"
                    show-empty
                    :empty-html="emptyHtml"
                    :sort-desc.sync="isSortDirDesc"
                    class="position-relative"
                    @refreshed="onTableRefresh"
                    v-model="currentItems"
                    style="min-height: 500px"
                >
                    <template #head(action)="data">
                        <span>{{ data.label.toUpperCase() }}</span>
                        <div class="sk-column-control">
                            <b-dropdown
                                no-caret
                                variant="button"
                                toggle-class="p-0"
                                :right="true"
                                class="pr-1 pl-1 d-flex sk-column-filter"
                                v-if="betaFeature"
                                v-b-tooltip = "i18nT(`Customize columns`)"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="SettingsIcon"
                                        size="18"
                                        class="text-right"
                                    />
                                </template>

                                <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                    <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                </div>
                            </b-dropdown>
                        </div>
                    </template>
                    <template #cell(from)="data">
                        <h5>
                            {{data.item.StartDate | date}}
                        </h5>
                    </template>

                    <template #cell(to)="data">
                        <h5>
                            {{data.item.EndDate | date}}
                        </h5>
                    </template>

                    <template #cell(totalDays)="data">
                        <h5>
                            {{ data.item.WorkingDays }}
                        </h5>
                    </template>



                    <template #cell(type)="data">
                        <h5>
                            {{ getAbsenceTypeTitle(data.item.AbsenceType) }}</h5>
                    </template>

                    <template #cell(status)="data">
            <span
                v-b-tooltip.hover.top="data.item.RejectedReason"
                :class="statusBadgeClasses(data.item)"
            >
              {{ getStatusTitle(data.item.ApprovalStatus) }}
            </span>
                    </template>

                    <template #cell(createdBy)="data">
                        <h5>
                            {{ getCreatedTitle(data.item.CreatedBy) }}
                        </h5>
                    </template>

                    <template #cell(submittedOn)="data">
                        <h5>
                            {{data.item.CreatedAt | date}}
                        </h5>
                    </template>

                    <template #cell(action)="data">
                        <b-dropdown
                            variant="link"
                            toggle-class="p-0"
                            no-caret
                            v-if="data.item.ApprovalStatus !== 1 && data.item.CreatedBy === 0"
                        >
                            <template #button-content >
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item v-if="data.item.ApprovalStatus == 2" @click="rejectReason(data)">
                                <feather-icon icon="ThumbsDownIcon" />
                                <span class="align-middle ml-50" >{{i18nT(`Rejected Reason`)}}</span>
                            </b-dropdown-item>

                            <b-dropdown-item v-if="data.item.ApprovalStatus != 2" @click="onEdit(data)">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50" >{{i18nT(`Edit`)}}</span>
                            </b-dropdown-item>

                            <b-dropdown-item v-if="data.item.ApprovalStatus != 2" @click="onDelete(data)">
                                <feather-icon icon="TrashIcon" class="text-danger" />
                                <span class="align-middle ml-50 text-danger">{{i18nT(`Delete`)}}</span>
                            </b-dropdown-item>

                        </b-dropdown>

                    </template>
                </b-table>

                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
                        >
              <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>
        <!-- MODAL -->

        <b-modal
            id="modal-reason"
            :title="i18nT(`Reason for rejection`)"
            ok-only
            :ok-title="i18nT(`Ok`)"
            no-close-on-backdrop
        >
            <p>{{reason}}</p>
        </b-modal>

        <modal-leave-request :refetchData="refetchData" :extEmployeeId="curUserId" :ext-leave="currentLeave" @resetForm="resetForm"></modal-leave-request>

        <!-- MODAL REQUEST -->
        <!-- <b-modal
            id="modal-request"
            :title="i18nT(`Time off request`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="saveLeaveRequest"
            @hidden="resetForm"
        >
            <validation-observer ref="requestForm">
                <b-form>
                    <b-row>
                        <b-col sm="12">
                            <b-row>
                                <b-col md="12">
                                    <p>{{ i18nT(`Request time off and select the type of absence.`) }}</p>


                                    <b-row>
                                        <b-col cols="12" class="text-center py-2">
                                            <h3 class="d-inline">{{i18nT(`Editing time off for`)}}</h3>
                                            <b-form-select
                                                v-model="requestYear"
                                                :options="availableYears"
                                                class="d-inline"
                                                @change="updateLeaveStats(requestYear)">

                                            </b-form-select>
                                        </b-col>

                                    </b-row>

                                    <div class="d-flex mb-2">
                                        <div
                                            class="bg-light text-center mr-1 p-1 rounded"
                                            style="width: 100px;"
                                        >
                                            <p class="font-weight-bolder">{{ i18nT(`Available`) }}</p>
                                            <p
                                                class="text-success font-weight-bolder"
                                                style="font-size: 26px;line-height: 10px;"
                                            > {{ availableDays }} </p>
                                            <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                        </div>
                                        <div
                                            class="bg-light text-center mr-1 p-1 rounded"
                                            style="width: 100px;"
                                        >
                                            <p class="font-weight-bolder">{{ i18nT(`Taken`) }}</p>
                                            <p
                                                class="text-primary font-weight-bolder"
                                                style="font-size: 26px;line-height: 10px;"
                                            > {{ takenDays }} </p>
                                            <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                        </div>
                                        <div
                                            class="bg-light text-center p-1 rounded"
                                            style="width: 100px;"
                                        >
                                            <p class="font-weight-bolder">{{ i18nT(`Total`) }}</p>
                                            <p
                                                class="font-weight-bolder"
                                                style="font-size: 26px;line-height: 10px;"
                                            > {{ totalDays }} </p>
                                            <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`From date`)"
                                        label-for="message"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="From"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="requestFrom"
                                                :max="requestTo"
                                                placeholder=""
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`To date`)"
                                        label-for="message"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="To"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="requestTo"
                                                :min="requestFrom"
                                                placeholder=""
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Absence type`)"
                                        label-for="message"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="To"
                                            rules="required"
                                        >
                                            <v-select
                                                v-model="requestType"
                                                :options="requestTypeOptions"
                                                placeholder=""
                                                :state="errors.length > 0 ? false : null"
                                                :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                                label="text"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="12"
                                >
                                    <b-form-group
                                        :label="i18nT(`Note`)"
                                        label-for="Note"
                                    >
                                        <b-form-textarea
                                            :placeholder="i18nT(`Note`)"
                                            v-model="requestDescription"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="12">
                                    <p>{{ i18nT(`Attached scans and documents`) }}</p>
                                    <div v-if="currentLeave && currentLeave.AttachedFile">
                                        <b-link :href="currentLeave.AttachedFile.DownloadUrl">
                                            <feather-icon icon="FileIcon" size="19" class="mb-0"/>
                                            {{ currentLeave.AttachedFile.Label }}
                                        </b-link>
                                    </div>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="12"
                                >
                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        class="mr-1 mb-1 mt-2"
                                        @click="onFilePick"
                                    >
                                        <feather-icon icon="PlusCircleIcon"/>
                                        {{ i18nT(addFileLabel) }}
                                    </b-button>
                                    <b-form-file
                                        v-model="file"
                                        ref="filePicker"
                                        class="hidden"
                                    />
                                    <span v-if="file">
                                        {{ fileName }}
                                        <feather-icon
                                            icon="Trash2Icon"
                                            size="16"
                                            style="margin-right: 8px;"
                                            @click="onFileDelete"
                                        />
                                    </span>
                                    <span v-if="!file">
                                        {{ originalFileName }}
                                    </span>

                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal> -->
    </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BTable,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  // BListGroup, BListGroupItem,
  BFormSelect,
  BModal,
  BFormFile,
  //BFormDatepicker,
  BForm,
  VBTooltip, BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import useRequestList from "./useLeaveRequest";
import { avatarText } from "@core/utils/filter";
import {
    dictToSelectArrayWithTitle,
    dictToSelectArray,
} from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ModalLeaveRequest from '../../hr/LeaveRequest/ModalLeaveRequest.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DatePicker from '@core/components/DatePicker.vue'
import useAuth from "@/auth/useAuth";
import moment from 'moment'

// import _ from 'lodash'

export default {
    components: {
      BFormCheckbox,
        BRow,
        BCol,
        BFormGroup,
        BFormTextarea,
        BCard,
        BButton,
        BTable,
        BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        // BListGroup,
        // BListGroupItem,
        vSelect,
        BFormSelect,
        BModal,
        BFormFile,
        //BFormDatepicker,
        BForm,
        ValidationProvider,
        ValidationObserver,
        DatePicker,
        ModalLeaveRequest
        // ToastificationContent
        // VBModal
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            audienceGroups: [],
            requestTypeOptions: [{value: "", text: this.i18nT(`Any`)}],
            requestTypeOptionsForm: [],
            requestFrom: null,
            requestTo: null,
            requestType: null,
            requestYear: moment().year(),
            availableYears: [],

            description: '',
            approvalStatusOptions: [],
            createdByOptions: [
                {value: '', text: this.i18nT(`Anyone`)},
                {value: 0, text: this.i18nT(`Me`)},
                {value: 1, text: this.i18nT(`Employer`)}
            ],
            avalableFields: [
                {label: this.i18nT(`To`), key: 'to', visible: true},
                {label: this.i18nT(`Type`), key: 'type', visible: true},
                {label: this.i18nT(`Submitted on`), key: 'submittedOn', visible: true},
                {label: this.i18nT(`Created by`), key: 'createdBy', visible: true},
          ],
            required,
            currentLeave: null,
            file: null,
            fileName: null,
            originalFileName: null,
            reason: '',
            locale: 'en',
            availableDays: 0,
            takenDays: 0,
            totalDays: 0,
            requestDescription: '',
            curUserId: null,
            curUser: {}
        };
    },
    computed: {
        yesterday(){
            return moment().subtract(0, 'days').format('YYYY-MM-DD')
        },
        addFileLabel() {
            if(this.currentLeave && this.currentLeave.AttachedFile){
                return this.i18nT('Replace file')
            }else {
                return this.i18nT('Add file')
            }

        },
        emptyHtml() {
            // return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"+this.i18nT(`No matching records found`)+"</h4>";

            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
                + this.i18nT(`Here, you can create and manage your time off requests. No requests have been created yet.`) + "</h4>"
                +"<button class='btn btn-outline-secondary mt-2' id='leaveButton' >" + this.i18nT(`Create leave request`) + "</button>"
        },
        computedColumns() {
            let col = [...this.tableColumns]
            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('timeOffEmployee', this.avalableFields);
            return col
        },

    },
    updated(){
        const leaveBtn = document.querySelector('#leaveButton')
        if(leaveBtn) {
            leaveBtn.addEventListener('click', ()=> this.$bvModal.show('modal-request') );
        }
    },
    created() {
        this.getAbsenceType();
        this.getStatus();
        this.locale = this.$i18n.locale;

        this.curUser = useAuth.getProfile()
        this.curUserId = this.curUser.Id
        // this.getEmployeeLeaveDays(this.curUserId);
        let storePreferences = useAuth.getColumnPreferences('timeOffEmployee');
          if(storePreferences) {
            this.avalableFields = storePreferences
        }

    },
    methods: {
        updateLeaveStats(year) {
            this.$http.get(`leaves/leaveDays?employee_id=${this.curUserId}&year=${year}`)
                .then(({data}) => {
                    if(data.data.leave_days.length > 0) {
                            data.data.leave_days.map(data => {
                            this.availableDays = data.AvailableDays
                            this.takenDays = data.TakenDays
                            this.totalDays = data.TotalDays
                        })
                    }    
                })
        },
        resetModal(){

            this.requestFrom = null
            this.requestTo = null
            this.requestType = null
            this.description = ''
            this.file = null
            this.fileName = null
            this.originalFileName = null
            this.currentLeave = null
            this.requestDescription = ''
        },
        preloadAndShow() {
            this.resetModal();
            // this.getEmployeeLeaveDays(this.curUserId);
            this.$bvModal.show("modal-request");
        },
        getEmployeeLeaveDays(employeeId) {
            if (employeeId)
                this.$http
                    .get(`leaves/leaveDays?employee_id=${employeeId}`)
                    .then(({data}) => {
                        if(data.data.leave_days.length > 0) {
                            data.data.leave_days.map(data => {
                            this.availableDays = data.AvailableDays
                            this.takenDays = data.TakenDays
                            this.totalDays = data.TotalDays
                        })
                    }
                    })
        },
        onFilePick: function() {
            this.$refs['filePicker'].$refs.input.click()
        },
        onFileDelete: function() {
            this.file = null
            this.fileName = ''
        },
        statusBadgeClasses(item){
            let base_classes = 'badge badge-pill ';
            switch (item.ApprovalStatus) {
                case 0:
                    return base_classes+'badge-light-warning';
                case 1:
                    return base_classes+'badge-light-success';
                case 2:
                    return base_classes+'badge-light-danger';
                default:
                    return base_classes+'badge-light-secondary';
            }
        },
        onDelete(leave) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text:  this.i18nT(`You won't be able to revert this!`),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText:  this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.$http.delete(`leaves?id=${leave.item.Id}`).then(() => {
                        this.$swal({
                            icon: "success",
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The leave has been deleted.`),
                            customClass: {
                                confirmButton: "btn btn-success",
                            },
                        });
                        this.refetchData();
                    })
                        .catch((ex) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        });
                }
            })
        },
        onEdit(data) {
            this.leaveId = data.item.Id;
            this.currentLeave = data.item
            this.requestFrom = data.item.StartDate
            this.requestTo = data.item.EndDate
            this.requestDescription = data.item.Description
            this.requestType = data.item.AbsenceType
            this.requestEmployee = data.item.UserId
            this.curUserId = this.currentLeave.Id
            this.$bvModal.show('modal-request')
        },
        getStatus() {
            this.$http.get(`leaves/approvalStatusFilter`).then(({ data }) => {
                this.approvalStatusOptions = dictToSelectArray(data.data)
                console.log("Approval status options: ", this.approvalStatusOptions);
            });
        },
        getAbsenceType () {
            this.$http.get(`hr/absenceTypes`).then(({ data }) => {
                let types = data.data.map((item) => ({
                    value: item.Id,
                    text: item.Label
                }))
                this.requestTypeOptionsForm = types;
                this.requestTypeOptions = [...this.requestTypeOptions, ...types]
            });
        },
        getCreatedTitle(createdBy){
            if(createdBy) {
                return this.i18nT(`Employer`)
            } else {
                return this.i18nT(`Me`)
            }
        },
        getNameByAudienceGroupId(audienceGroupId) {
            const audienceGroup = this.audienceGroups.find(
                (audienceGroup) => audienceGroup.value == audienceGroupId
            );
            return audienceGroup ? audienceGroup.text : "";
        },
        removeLabel(label) {
            console.log(label);
        },
        getLabels(labelArray) {
            return dictToSelectArrayWithTitle(labelArray);
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(" ")
                    .map((namePart) => {
                        return namePart[0];
                    })
                    .join("")
                    .toUpperCase();
            } else {
                return "";
            }
        },
        onTableRefresh() {
            this.selectedItems = [];
        },
        onBulkCopy() {
            this.$http
                .post(`surveys/copySurvey?id=${this.selectedItems.join(",")}`)
                .then(() => {
                    this.selectedItems = [];
                    this.selectAllStatus = false;
                    this.refetchData();
                });
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.$http
                        .delete(`surveys?id=${this.selectedItems.join(",")}`)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The surveys had been deleted.`),
                                customClass: {
                                    confirmButton: "btn btn-success",
                                },
                            });
                            this.selectedItems = [];
                            this.selectAllStatus = false;
                            this.refetchData();
                        });
                }
            });
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map((item) => item.Id);
            } else {
                this.selectedItems = [];
            }
        },
        onPutInFolder(data) {
            console.log(data);
            // this.$bvModal.show('modal-pipeline');
        },
        saveLeaveRequest(event) {
            event.preventDefault();
            this.$refs.requestForm.validate().then((success) => {
                if (success) {
                    //TODO
                    const formData = new FormData();
                    if(this.currentLeave) {
                        formData.append("id", this.currentLeave.Id);
                    }
                    formData.append("AbsenceType", this.requestType);
                    formData.append("StartDate", this.requestFrom);
                    formData.append("EndDate", this.requestTo);
                    formData.append("HalfDay", 0);
                    formData.append("Description", this.description);
                    formData.append('attached_file', this.file)

                    this.$http
                        .post("leaves", formData)
                        .then((res) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: this.successMessage(res),
                                    icon: "InfoIcon",
                                    variant: "success",
                                },
                            });
                            this.selectedItems = [];
                            this.$bvModal.hide("modal-request");
                            this.resetModal();
                            this.refetchData();
                            this.getEmployeeLeaveDays(this.curUserId);
                        })
                        .catch((ex) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: "AlertTriangleIcon",
                                    variant: "danger",
                                },
                            });
                        });
                }
            });
        },
        getAbsenceTypeTitle(typeId) {
            try {
                return this.requestTypeOptions.find(option => option.value === typeId).text
            }
            catch(ex) {
                console.log(ex)
                return ''
            }
        },
        getStatusTitle(statusId) {
            try {
                return this.approvalStatusOptions.find(option => option.value === statusId + "").text
            }
            catch(ex) {
                console.log(ex)
                return ''
            }
        },
        rejectReason(data) {
            this.reason = data.item.RejectedReason
            this.$bvModal.show("modal-reason");
        },
        resetForm() {
            this.availableDays = 0
            this.takenDays = 0
            this.totalDays = 0
            this.currentLeave = {}
            this.description = ''
            this.file = null
            this.fileName = null
            this.originalFileName = null
            this.requestFrom = ''
            this.requestTo = ''
            this.requestType = ''
            this.curUserId = null
        },
    },
    watch: {
        // currentLeave: function(val) {
        //     if (val) {
        //         this.requestYear = moment(val.StartDate).year()
        //         this.requestFrom = val.StartDate
        //         this.requestTo = val.EndDate
        //         this.requestType = val.AbsenceType
        //         this.description = val.Description
        //         this.originalFileName = val.AttachedFile ? val.AttachedFile.Label : ''
        //     } else {
        //         this.requestFrom = ''
        //         this.requestTo = ''
        //         this.requestType = ''
        //         this.description = ''
        //         this.originalFileName = ''
        //     }
        // },
        file: function(val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        },
    },
    setup() {
        const {
            fetchRequests,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            from,
            to,
            type,
            createdBy,
            status,
            surveys,
            refetchData,
        } = useRequestList();

        return {
            fetchRequests,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            from,
            to,
            type,
            createdBy,
            status,
            avatarText,
            surveys,
            refetchData,
        };
    },
};
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
.section-title {
    font-size: 11px;
}
.document-name {
    display: inline;
    line-height: 32px;
}
.document-actions-bar {
    float: right;
}
.ql-editor {
    min-height: 200px;
}
.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}
.cv-card {
    min-height: 180px;
    span {
        font-size: 12px;
    }
}

</style>
